import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckDeviceService {
  constructor() { }

  isAndroid(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  isiOS(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  // Version example: 15_1_1
  iOSVersion(version: string): boolean {
    return navigator.userAgent.includes(`OS ${version}`);
  }

  isMobile(): boolean {
    return this.isAndroid() || this.isiOS();
  }

  isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
}
